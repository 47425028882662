<template>
  <div class="error">
    {{$t('error.message')}}
  </div>
</template>

<script>
  export default {
    name: 'Error'
  };
</script>
